document.addEventListener('DOMContentLoaded', (e) => {

	const blocks = document.querySelectorAll('.block--next-section');

	function create_block_next() {

		return {
			block: null,

			init: function (block) {
				this.block = block;
				this.listeners();
			},

			listeners: function() {
				let button = this.block.querySelector('button');

				button.addEventListener('click', (e) => {					
					var elements = document.getElementsByClassName('block--section-wrapper');				
					var scrollPosition = window.scrollY || document.documentElement.scrollTop;
				
					for (var i = 0; i < elements.length; i++) {
						if (elements[i].offsetTop > scrollPosition) {
							elements[i].scrollIntoView({behavior: "smooth"});
							break;
						}
					}
				});
			},

			log: (message) => {
				console.log(message);
			}
		}
	}

	blocks.forEach(block => {
		const block_next = create_block_next();
		block_next.init(block);
	});
});